/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.login-title {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 69px;
}
.login-content {
  width: 1300px;
  height: 650px;
  position: relative;
}
.login-content .content-form {
  width: 450px;
  height: 100%;
  position: absolute;
  right: 50px;
}
.login-content .content-form h1 {
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin: 60px 0;
}
.login-content .content-form .form-box ::v-deep .ant-input-prefix {
  font-size: 26px;
}
.login-content .content-form .form-box ::v-deep .ant-input {
  border-radius: 40px;
  height: 50px;
  padding-left: 45px;
  font-size: 20px;
}
.login-content .content-form .form-box ::v-deep .ant-input-password .ant-input-suffix {
  right: 20px;
}
.login-content .content-form .form-box ::v-deep .ant-input-password .ant-input-suffix .anticon {
  font-size: 20px;
}
.login-content .content-form .form-box ::v-deep.code-input .ant-input {
  padding-left: 20px;
}
.login-content .content-form .form-box ::v-deep.code-input .ant-input-suffix {
  height: 45px;
  width: 110px;
  display: flex;
  padding: 4px;
  align-items: center;
  margin-right: -10px;
}
.login-content .content-form .form-box ::v-deep.code-input .ant-input-suffix .code-img {
  width: 100%;
  height: 100%;
  background: skyblue;
  border-radius: 30px;
}
.login-content .content-form .form-box .err-msg-form {
  position: relative;
  top: -20px;
  margin-bottom: 0;
}
.login-content .content-form .form-box .err-msg-form .err-msg {
  padding: 0 20px;
  color: red;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.login-content .content-form .form-box .login-btn {
  height: 50px;
  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  background: linear-gradient(90deg, #fd7163, #ffb940);
  border: 0 !important;
}
